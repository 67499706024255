/*
Template Name: Osahanin - Job Portal & Social Network HTML Template
Author: Askbootstrap
Author URI: https://themeforest.net/user/askbootstrap
Version: 1.0
*/
/*
    -- body
    -- Slider
    -- Top Nav
    -- Card Design
    -- Tab Design
    -- Login Page Designs
    -- People List
    -- Job List
    -- Network Item
    -- FAQ
    -- Chat
    -- Blog
    -- Gallery / Review
    -- Extra
    -- Mobile Media
*/

@font-face {
    font-family: 'FuturaDisD';
    font-style: normal;
    font-weight: 400;
    /*src: url('../fonts/FuturaDisD.eot'); !* IE9 Compat Modes *!*/
    src: local('FuturaDisD'), url('./../fonts/FuturaDisD.woff2') format('woff2'), /* Super Modern Browsers */
    url('./../fonts/FuturaDisD.woff') format('woff'), /* Modern Browsers */
    url('./../fonts/FuturaDisD.ttf') format('truetype') /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Futura-Medium';
    font-style: normal;
    font-weight: 400;
    /*src: url('../fonts/FuturaDisD.eot'); !* IE9 Compat Modes *!*/
    src: local('Futura-Medium'), url('./../fonts/Futura-Medium.woff2') format('woff2'), /* Super Modern Browsers */
    url('./../fonts/Futura-Medium.woff') format('woff'), /* Modern Browsers */
    url('./../fonts/Futura-Medium.ttf') format('truetype') /* Safari, Android, iOS */

}

::-webkit-scrollbar {
    width: 8px;
}

 ::-webkit-scrollbar-thumb {
    background-color: #006afe;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    opacity: 0.5;
    background-color: rgba(0, 106, 254, 0.8);
}



body {
    font-family: 'Futura-Medium';
}


 @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* body */
 body {
     background: #e5eaf0;
     font-size: 13px;
     font-family: 'Futura-Medium', sans-serif;
     padding-bottom: 0;
}
#root{
    overflow: hidden;
}
 p{
     color: #000;
     font-weight: 500;
}
/* End body */
/* Slider */
 .slick-arrow:before {
     color: #000;
     z-index: 99999;
}
 .slick-prev {
     left: 0;
     display: none;
}
 .slick-next {
     right: 9px;
     z-index: 9;
     background: #fff;
     border-radius: 50px;
     box-shadow: 0 6px 22px 0 rgba(0,0,0,0.08) !important;
}
/* End Slider */
/* Top Nav */
 .navbar-dark .navbar-nav .nav-link {
     color: rgba(255,255,255,.8);
}
 .osahan-nav-top .container {
     padding: 0 16px;
}
 .osahan-nav-top .img-profile {
     width: 30px;
     height: 30px;
}
.osahan-nav-top .navbar-brand img {
    height: 31px;
    margin: -4px 0 0 0;
}
 .osahan-nav-top .osahan-list-dropdown .nav-link i {
     font-size: 16px;
}
 .osahan-nav-top .osahan-list-dropdown .nav-link .badge {
     position: absolute;
     border-radius: 50px;
     min-width: 15px;
     height: 15px;
     font-size: 9px;
     padding: 0px;
     right: 1px;
     line-height: 15px;
     top: 11px;
}
 .osahan-nav-top .nav-link {
     position: relative;
     height: 55px;
     display: flex;
     align-items: center;
}
 .osahan-nav-top .navbar-search .form-control {
     background: #fff;
     color:#000;
     height: 35px;
}
.osahan-nav-top .navbar-search .form-control::placeholder {
    color:#000;
    font-size: 16px;
}
 .osahan-nav-top .navbar-search .btn {
     background: #fff !important;
     color: #000;
     font-size: 14px;
     padding-right: 0;
}
 .dropdown-list .dropdown-item {
     white-space: normal;
     padding-top: .5rem;
     padding-bottom: .5rem;
     border-left: 1px solid #e3e6f0;
     border-right: 1px solid #e3e6f0;
     border-bottom: 1px solid #e3e6f0;
     line-height: 1.3rem;
}
 .dropdown-list {
     padding: 0;
     border: none;
     overflow: hidden;
     width: 20rem!important;
}
 .dropdown-list .dropdown-header {
     background-color: #1d2f38;
     border: 1px solid #1d2f38;
     color: #fff;
     font-weight: 800;
     font-size: 14px;
     padding: .8rem 1rem;
}
 .dropdown-list .icon-circle {
     height: 2.5rem;
     width: 2.5rem;
     border-radius: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
}
/* End Top Nav */
/* Card Design */
 .box.border {
     border: 1px solid #ffffff !important;
}
 .shadow-sm {
     box-shadow: 0 6px 22px 0 rgba(0,0,0,0.08) !important;
}
/* End Card Design */
/* Tab Design */
 .osahan-line-tab .nav-link {
     font-size: 14px;
     font-weight: 500;
     padding: 1rem!important;
     color:#888da8;
     position: relative;
}
 .osahan-line-tab .nav-link.active {
     color: #007bff;
}
 .osahan-line-tab .nav-link.active:after {
     content: "";
     background: -moz-linear-gradient(194deg, #00c9e4 0%, #007bff 100%);
    /* ff3.6+ */
     background: -webkit-gradient(linear, left top, right top, color-stop(0%, #007bff), color-stop(100%, #00c9e4));
    /* safari4+,chrome */
     background: -webkit-linear-gradient(194deg, #00c9e4 0%, #007bff 100%);
    /* safari5.1+,chrome10+ */
     background: -o-linear-gradient(194deg, #00c9e4 0%, #007bff 100%);
    /* opera 11.10+ */
     background: -ms-linear-gradient(194deg, #00c9e4 0%, #007bff 100%);
    /* ie10+ */
     background: linear-gradient(256deg, #00c9e4 0%, #007bff 100%);
    /* w3c */
     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007bff', endColorstr='#00c9e4',GradientType=1 );
    /* ie6-9 */
     height: 3px;
     position: absolute;
     bottom: -2px;
     left: 0;
     right: 0;
}
/* End Tab */
/* Login Page Designs */
 .osahan-login .form-control {
     background: #f8fafc;
     border-color: #eaebec;
}
 .icon-form-control i {
     display: flex;
     top: 0;
     bottom: 0;
     align-items: center;
     font-size: 16px;
     justify-content: center;
     width: 40px;
}
 .icon-form-control .form-control {
     padding-left: 40px;
}
 label {
     font-weight: 500;
}
 .custom-control label {
     line-height: 22px;
     font-weight: 400;
}
 .btn-outline-instagram {
     border-color: #2a5b83;
     background-color: #2a5b83;
     color: #fff;
}
 .btn-outline-linkedin {
     border-color: #187fb9;
     background-color: #187fb9;
     color: #fff;
}
 .btn-outline-facebook {
     border-color: #43619d;
     background-color: #43619d;
     color: #fff;
}
/* End Login Page Designs */
/* People List */
 .people-list .font-weight-bold {
     font-weight: 500 !important;
     word-break: break-all;
     overflow: hidden;
     white-space: nowrap;
}
 .people-list .btn-sm {
     font-size: .800rem;
}
 .people-list .font-weight-bold div {
     text-overflow: ellipsis;
     overflow: hidden;
}
/* End People List */
/* Job List */
 .job-item-header .img-fluid, .job-item-2 .img-fluid {
     width: 40px;
     height: 40px;
}
/* End Job List */
/* Network Item */
 .overlap-rounded-circle .rounded-circle {
     width: 22px;
     height: 22px;
     border: 2px solid #fff;
     margin: 0 0 0 -9px;
}
 .overlap-rounded-circle {
     margin-right: 9px;
     padding-left: 9px;
}
 .image-overlap-2 .img-fluid {
     width: 75px;
     height: 75px;
     border: 4px solid #fff;
     margin: 0 -12px;
}
/* End Network Item */
/* FAQ */
.card-btn-arrow {
   display: inline-block;
   color: #377dff;
   margin-left: 1rem;
   transition: 0.3s ease-in-out;
}
.collapsed .card-btn-arrow {
   -webkit-transform: rotate(-90deg);
   transform: rotate(-90deg);
}
/* End FAQ */
/* Chat */
.osahan-chat-box {
   height: 322px;
   overflow-y: scroll;
}
.osahan-chat-list {
   overflow-y: auto;
   height: 463px;
   min-height: 463px;
}
/* End Chat */
/* Blog */
.blog-card .card-footer img {
    margin: 0 11px 0 0;
     width: 33px;
 }
 .blog-card .badge {
     font-size: 12px;
     font-stretch: normal;
     font-style: normal;
     font-weight: 500;
     letter-spacing: 1px;
     line-height: normal;
     margin: 0 0 12px;
     padding: 6px 10px;
     text-transform: uppercase;
 }
 .blog-card .badge-primary {
     background-color: rgba(92, 93, 232, 0.1);
     color: #5c5de8;
 }
 .blog-card .badge-danger {
     background-color: rgba(250, 100, 35, 0.1);
     color: #fa6423;
 }
 .blog-card .badge-success {
     background-color: rgba(0, 216, 200, 0.09);
     color: #00d8c8;
 }
 .blog-card .badge-dark {
     background-color: rgba(52, 58, 64, 0.19);
     color: #343a40;
 }
 .blog-card .badge-info {
     background-color: rgba(23, 160, 184, 0.17);
     color: #17a2b8;
 }
 .blog-card .badge-white {
     background-color: rgba(255, 255, 255, 0.81);
     color: #5c5de8;
 }
 .reviews-card .d-flex {
     height: 50px;
     width: 50px;
 }
 .reviews-card h5 small {
     color: #848484;
     font-size: 12px;
     margin: 0 0 0 8px;
 }
 .star-rating i {
    font-size: 11px;
    letter-spacing: -1px;
 }
 .list-icon i {
     float: left;
     font-size: 36px;
     line-height: 47px;
     width: 52px;
 }
 .list-icon {
     margin-bottom: 24px;
 }
 .list-icon strong {
     text-transform: uppercase;
     vertical-align: text-top;
 }
 .list-icon p {
     line-height: 11px;
 }
 .property-single-title {
     bottom: 0;
     left: 0;
     padding: 37px 0;
     position: absolute;
     right: 0;
 }
.reviews-card h5.mt-0 {
    font-size: 14px;
}
ul.sidebar-card-list {
    margin: 0;
    padding: 0;
}
.sidebar-card-list li a {
    display: inline-block;
    width: 100%;
    color: #585b5f;
}
.sidebar-card-list > li {
    line-height: 32px;
    list-style: none;
}
.footer-social a {
    background: #007bff;
    color: #fff;
    width: 24px;
    height: 24px;
    display: inline-block;
    text-align: center;
    line-height: 25px;
    border-radius: 2px;
}
/* End Blog */
/* Gallery / Review */
.reviews-members img.mr-3 {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.reviews-members {
    border-bottom: 1px solid #eaebec;
    padding: 17px 17px;
    margin: 0 -17px;
}
.reviews-members:last-child {
    border: none;
}
.gallery-box-main {
    border-radius: 10px;
    overflow: hidden;
}
.gallery-box:last-child {
    margin-bottom: 0px;
}
.gallery-box img:last-child {
    margin-right: 0px;
}
.gallery-box {
    align-items: center;
    display: flex;
    margin-bottom: 2px;
    justify-content: space-between;
}
.gallery-box img {
    width: 34%;
    border-right: 2px solid transparent;
}
/* End Gallery / Review */
/* Extra */
 a{
     text-decoration: none !important;
}
 div, a{
     outline: none !important;
}
 .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
     padding-right: 8px;
     padding-left: 8px;
}
 .row {
     margin-right: -8px;
     margin-left: -8px;
}
 .osahan-share-post textarea {
     resize: none;
}
 .text-success {
     color: #00c9a7 !important;
}
 .text-danger {
     color: #de4437 !important;
}
 .text-warning {
     color: #ffc107 !important;
}
 .text-info {
     color: #00dffc !important;
}
 .bg-success {
     background-color: #00c9a7 !important;
}
 .bg-danger {
     background-color: #de4437 !important;
}
 .bg-warning {
     background-color: #ffc107 !important;
}
 .bg-info {
     background-color: #00dffc !important;
}

 .bg-white{
     background-color:#fff ;
 }
 .badge-success {
     color: #fff;
     background-color: #00c9a7;
}
 .badge-danger {
     color: #fff;
     background-color: #de4437;
}
 .badge-warning {
     color: #1e2022;
     background-color: #ffc107;
}
 .badge-info {
     color: #1e2022;
     background-color: #00dffc;
}
 .border, .card {
     border: 1px solid #eaebec!important;
}
 .card {
     border:0;
     box-shadow: 0 0 4px 1px #bbb;
 }
 .border-top {
     border-top: 1px solid #eaebec!important;
}
 .border-bottom {
     border-bottom: 1px solid #eaebec!important;
}
 .border-left {
     border-left: 1px solid #eaebec!important;
}
 .border-right {
     border-right: 1px solid #eaebec!important;
}
 .list-group-item{
     border-color: #eaebec!important;
}
 .btn-light {
     color: #007bff;
     background-color: #ebf2ff;
     border-color: #ebf2ff;
}
 .btn-light:hover {
     color: #007bff;
     background-color: #ffffff;
     border-color: #007bff;
}
 .btn-primary{
     background: -moz-linear-gradient(194deg, #00c9e4 0%, #007bff 100%);
    /* ff3.6+ */
     background: -webkit-gradient(linear, left top, right top, color-stop(0%, #007bff), color-stop(100%, #00c9e4));
    /* safari4+,chrome */
     background: -webkit-linear-gradient(194deg, #00c9e4 0%, #007bff 100%);
    /* safari5.1+,chrome10+ */
     background: -o-linear-gradient(194deg, #00c9e4 0%, #007bff 100%);
    /* opera 11.10+ */
     background: -ms-linear-gradient(194deg, #00c9e4 0%, #007bff 100%);
    /* ie10+ */
     background: linear-gradient(256deg, #00c9e4 0%, #007bff 100%);
    /* w3c */
     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007bff', endColorstr='#00c9e4',GradientType=1 );
    /* ie6-9 */
     border-color: #007bff;
}
 .text-gold {
     color: #d3ac2b;
}
 .btn-outline-gold {
     color: #d3ac2b;
     border-color: #d3ac2b;
}
 .btn-outline-gold:hover {
     color: #ffffff;
     background-color: #d3ac2b;
     border-color: #d3ac2b;
}
 .dropdown-menu{
     border-color: #eaebec;
}
 .dropdown-item {
     padding: 6px 1rem;
}
 .btn-outline-secondary {
     color: #b7b9cc;
     border-color: #b7b9cc;
}
 .btn-outline-secondary:hover, .btn-outline-secondary:focus {
     color: #ffffff;
     border-color: #b7b9cc;
     background-color: #b7b9cc;
}
 .bg-dark {
     background: #000 !important;
}
 .form-control, .btn {
     font-size: 13px;
}
.input-group-text, .custom-select{
  font-size: 13px;
}
 .text-dark {
     color: #1d2f38 !important;
}
 .small, small {
     font-size: 12px;
}
 .no-arrow .dropdown-toggle::after {
     display: none;
}
 .text-truncate {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
}
 .dropdown .dropdown-menu, .dropdown-item {
     font-size: .85rem;
}
 .text-gray-500 {
     color: #b7b9cc!important;
}
 .dropdown-list-image {
     position: relative;
     height: 2.5rem;
     width: 2.5rem;
}
 .dropdown-list-image img {
     height: 2.5rem;
     width: 2.5rem;
}
 .dropdown-list-image .status-indicator {
     background-color: #eaecf4;
     height: .75rem;
     width: .75rem;
     border-radius: 100%;
     position: absolute;
     bottom: 0;
     right: 0;
     border: .125rem solid #fff;
}

 .profile-account-holder .profile-box{

 }

 .profile-account-holder .profile-box .profile-main-data-container{

     flex: 0 0 100%;
 }

.profile-account-holder .profile-box .profile-image-box{
    flex: 0 0  100%;
    text-align: center;
}
.profile-box > div{
    flex-direction: column;
}
.profile-account-holder .profile-box h5{

}
.post-image-block{
    width: 100%;
    height: 400px !important;
}
.post-image-block img,
.post-image-block video{
    width: 100%;
    height: 400px;
    object-fit: contain;
}
.slick-next {
    right: 15px !important;
}
.slick-prev {
    left: 15px !important;
    z-index: 1;
}
.slick-prev:before, .slick-next:before{
    color: #2c78eb !important;
}
.slick-list {
    margin: 0 -10px !important;
}

.slick-slide > div {
    padding: 0 10px !important;
}
.slider-item-list{
    display: flex;
    flex-direction: column;
    
}
.slider-item-list .p-3{
    margin-top: auto;
}
.slider-item-list.profile-account-holder{
    /* box-shadow: none !important; */
    margin-bottom: 0px;
    height: 100%;
}
.tabbar-list{
    z-index: 10;
    
}
.swiper{
    height: 100%;
}
/* Extra End */
/* Mobile Media */
@media (max-width: 768px) {
	.osahan-nav-top .dropdown-menu {
		width: 100% !important;
	}
	.osahan-nav-top .nav-item.dropdown {
		position: unset;
	}
	.osahan-line-tab .nav-link {
		font-size: 13px;
	}
	.job-tags .btn {
		margin: 3px 1px !important;
	}
	.profile-right {
		display: inline-grid;
	}
	.profile-right .btn {
		margin: 2px 0 !important;
	}
}
/* End Mobile Media */
.btn-primary{
    background: #006afe;
}
.btn-dark{
    background: #000;
}
.btn-full-width{
    width:100%;
}

.btn-radius-50{
    border-radius: 17px;
}

.btn-radius-25{
    border-radius: 13px;
}
.btn-radius-0{
    border-radius: 0;
}
.indicators-profile{
    margin-bottom: 20px;
}
.online .profile-image-box .rounded-circle{

}
a, .blue-text{
    color:#006aff;
}
.font-weight-700{
    font-weight: 700;
}

.font-weight-600{
    font-weight: 600;
}
.font-weight-500{
    font-weight: 500;
}
.font-weight-400{
    font-weight: 400 !important;
}
.font-weight-300{
    font-weight: 300;
}
.font-weight-200{
    font-weight: 200;
}
.font-weight-100{
    font-weight: 100;
}
.form-control {
    border:0;
}

table.nobordered{

}

table.nobordered thead th,table.nobordered td{
    border:0;
    padding:.75rem 0;
}

.list-group-no-border{

}
.no-gutters{
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.list-group-no-border .list-group-item{
    border:0;
    font-size: 1.2rem;
}
.settings-badge .text-truncate{
    color:#5a5a5a;
    font-size: 1.1rem;
}
.settings-badge .dropdown-list-image img{
    height: 3rem;
    width: 3rem;
}

.profile-account-holder .indicators-profile .indicator-title{
    color: #666;
    font-size: 10px;
}

@media (min-width: 992px) {
    .profile-account-holder .profile-box .profile-main-data-container{

        flex: 0 0 100%;
    }

    .profile-account-holder .profile-box .profile-image-box{
        flex: 0 0  100%;
        text-align: center;
    }
    .profile-box > div{
        flex-direction: column;
    }
}
.card-recommendation-user .avatar-block{
    position: relative;
}
.card-recommendation-user .avatar-block .img-block{
    position: absolute;
    display: inline-block;
    width: 50px;

}
.card-recommendation-user .avatar-block .img-block img{
    border: 1px solid #f5f5f5;
    max-width: 100%;

}

.card-recommendation-user.online .avatar-block .img-block{
    border: 2px solid #0064ff;
    left: 15px;
    top: -25px;
}
.card-recommendation-user .card-body{
    padding: 10px;
}
.card-recommendation-user .card-body .card-title{
    padding-left:60px;
    padding-right: 25px;
    max-width: 100%;
}

.card-recommendation-user .card-body .card-title .title{

    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}
.card-recommendation-user .card-body .verify{
    width:20px;
    top:-5px;
    right: 0;


}

.card-recommendation-user .card-text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.card-recommendation-user .card-button{

}

.card.service-horizontal .card-button{
    position: absolute;
    bottom:0;
     left:0;
}
.card.service-horizontal .card-body{
    padding: 15px;
    padding-bottom: 44px;
}
.card.service-horizontal .card-text{
    -webkit-line-clamp: 2;
}
.card.service-horizontal .card-subtitle{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.card.service-horizontal .service-rate{
    right: 0;
    top:-8px;
    font-weight: 600;
    font-size: 14px;
}
.card.service-horizontal .card-subtitle{
    padding-right: 50px;
}
@media only screen and (max-width: 575px) {
    .card.service-horizontal .card-text{
        -webkit-line-clamp: 2;
    }
    .card.service-horizontal .card-body .card-subtitle {
        font-size: 14px;
    }

    .card.service-horizontal .service-rate{

        font-size: 14px;
    }
    .card.service-horizontal .card-text{
        font-size: 12px;
    }
     .service-button-add{
        position: fixed;
        width: 100%;
        bottom: 0;
         left:0;
    }
     .container{
         padding: 0;
     }
}
@media only screen and (min-width: 576px) and (max-width: 991px) {

    .card.service-horizontal .card-text{
        -webkit-line-clamp: 4;
    }
    .service-button-add{
        position: fixed;
        width: 100%;
        bottom: 0;
        left:0;
    }
}
@media only screen and (min-width: 992px) {
    .card.service-horizontal .card-text{
        -webkit-line-clamp: 4;
    }
}

.content-tabs{
    display: inline-block;
    position: relative;
    padding-left: 2px;
    padding-right: 2px;
    top:6px;
}

.content-tabs .content-tab-item{
    padding: 0 15px;

}
.content-tabs .content-tab-item.active i{
    border-bottom: 4px solid  #0064ff;
    ;
}
.content-tabs .content-tab-item i{
    color: #000;
    font-size: 25px;
    padding-bottom: 10px;

}
.profile-header{
    display: none;
}
.btn-circle{
    border-radius: 50%;
    padding: 6px 9px;;
}
@media (min-width: 992px) {

    .profile-account-holder .profile-header{
        display: block;
        background: #000;
        color:#fff;
        line-height: 35px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .profile-account-holder{
        border:1px solid #d6d6d6;
        border-radius: 6px;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    }
}
@media (max-width: 991px) {
    .osahan-nav-top .navbar-search .btn{
        padding-right: 12px;
    }
}

.login-page{

}
.login-page .images-block{
    display: flex;
}
.login-page .images-block .image-block{
    flex:0 0 14.2857%;
}
.login-page .images-block .image-block img{
    width:100%;
}
.login-page  .text-content .content{
    padding-left: 100px ;
    padding-top: 35px;
    padding-bottom: 200px;
}
.login-page  .text-content .content h2{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
}

.login-page  .text-content .content li{
    font-size:16px;
    margin-bottom: 20px;
}
.login-page .form-block form{
    padding: 35px;
    background: #fff;
}
.login-page .form-block form .form-control{
    background: #eee;
}
.login-page.registration-page .form-block form .form-control{
    background: #fff;
    border :2px solid #000;
    font-weight: 600;
}
.login-page .form-block form .form-group{
    margin-bottom: 20px;
}
.login-page .form-block form .forgot-password a{
    color:#000;
}
.login-page .or-block span{
    display: inline-block;
    padding: 20px;
    background: #fff;
}
.login-page .or-block hr{
    margin-top:30px;
    margin-bottom:30px;
    border-top-width: 2px;

}
.login-page .or-block .text-block{
    position: absolute;
    top:-30px;
    width: 100%;
}
.login-page.registration-page  .text-content .content{
    padding-bottom:500px ;
}

.login-page.registration-page .form-block .registration-nav{
    position: absolute;
    bottom:-70px;
    text-align: center;
    width: 100%;
}
.login-page.registration-page .form-block .registration-nav ul{
    padding: 0;
}
.login-page.registration-page .form-block .registration-nav ul li{
    display: inline-block;
    padding: 4px;
}
.login-page.registration-page .form-block .registration-nav ul li .circle{
    width: 10px;
    height: 10px;
    background: #000;
    display: inline-block;
    opacity: 0.4;
    border-radius: 50%;
}

.login-page.registration-page .form-block .registration-nav ul li.active .circle{
    opacity: 1;
}
@media (min-width: 992px){
    .login-page .form-block{
        width: 400px;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        position: absolute;

        right: 65px;
        top: 100px;
    }
    .login-page.registration-page .form-block{
        left:calc(50% - 200px);
    }
    .login-page .text-content{
        position: absolute;
        width: 100%;
        top:180px;
    }

}
@media (max-width: 991px){
    .login-page{

    }
    .login-page .images-block{
        display: none;
    }
    .login-page .text-content{
        display: none;
    }
}
.login-page .form-header{
    background: #fff;
    padding: 15px;
}
.login-page .form-header img{
    max-width: 150px;
}

.service-simple{}

.service-simple .image-block{

}
.service-simple .image-block img{
    width:100%;
}
.service-simple .text-block{
    padding:15px 30px;
}
.service-simple .text-block .title-service-block{

}
.service-simple .text-block .title-service-block .title-service{
    padding-right: 60px;
}
.service-simple .text-block .title-service-block .service-rate-block{
    right: 0;
    top: 0;
    font-size:18px;
    font-weight: 600;
}
.service-simple .service-order-tabs-container{}
.service-simple .service-order-tabs-container .tabs-navs{
    box-shadow: 0 3px 2px -2px #e5e5e5;
}
.service-simple .service-order-tabs-container .tabs-navs .tab-nav{

}
.service-simple .service-order-tabs-container .tabs-navs .tab-nav a{
    font-size: 17px;
    font-weight: 600;
    color:#5d5d5d;
    display: block;
    padding: 12px;
}
.service-simple .service-order-tabs-container .tabs-navs .tab-nav.active a{
    color:#006afe;
    border-bottom: #006afe solid 4px;
}
.service-simple .tabs-container .tab-content{
    padding:15px 30px;
}
.service-simple .tabs-container .tab-content p{
    margin: 0;
    line-height: 20px;
    font-size: 12px;
    font-weight: 500;
    color: #777777;
}
.service-simple .tabs-container .tab-content ul{
    padding:0;
    list-style-type: none;
}
.service-simple .tabs-container .tab-content ul li{
    margin: 0;
    line-height: 20px;
    font-size: 12px;
    font-weight: 500;
    color: #777777;
}
.service-simple .tabs-container .tab-content ul li:before {
    content: "- ";
}

.service-simple .service-button-place-order{
    padding: 15px 30px;
}
@media (max-width: 991px){
    .service-simple .service-button-place-order{

    padding: 0;
    }
    .service-simple .service-button-place-order button{
        width:100% ;
        position: fixed;
        bottom: 0;
        left: 0;
    }
}
.profile-account-holder .content-block{
    padding: 15px 30px;
}


.request-call .radio-control{
    margin-bottom: 10px;
}
.request-call .radio-control input[type="radio"]{
    display: none;
}
.request-call .radio-control span{
    border:2px solid #b9b9b9;
    padding: 12px 15px;
    margin-right: 10px;
    display: inline-block;
    color:#808080;
    font-weight: 500;
    border-radius: 24px;
}
.request-call .radio-control input[type="radio"]:checked + span{
    border-color: #006aff;
    color:#006aff;
}
@media (max-width: 991px){
    .bottom-fixed-button button{
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
    }
}

.form-control.bordered{
    border: 2px solid #000;
    display:inline-block;
    font-weight: 600;
    color:#000;
}

.form-control.bordered.error{
    border-color: #f00;
}

.form-control::placeholder {
    font-weight: 600;
    color:#000;
}

@media (max-width: 991px){
    .desktop{
        display: none;
    }
    .mobile.navbar {}
    .mobile.navbar .nav-block{
        width:100%;
    }
    .mobile.navbar .nav-text{
        font-size: 24px;
        color:#000;
        line-height: 55px;
    }
    .mobile.navbar .nav-text a{
        font-size: 24px;
        line-height: 55px;
        color:#000;
    }
}

@media (min-width: 992px){
    .mobile{
        display: none;
    }
    .desktop.navbar-dark .navbar-nav .nav-link{
        font-size: 14px;
        color: #000;
        font-weight: 500;
        white-space: nowrap;

    }
    .desktop.navbar-dark .navbar-nav .nav-link:hover{
        color:#2b78ea;
    }
    .desktop.navbar-dark .navbar-nav .nav-link i{
        font-size: 18px;
    }
}

.masonry-gallery {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
}
.masonry-gallery_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.masonry-gallery_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 10px;}

.masonry-gallery .masonry-gallery_column .masonry-gallery-item{

}
.masonry-gallery .masonry-gallery_column .masonry-gallery-item img{
    width:100%;
}
.react-date-picker__wrapper{
    border: 0!important;
}

.react-date-picker__button svg {
    display: inherit;
    position: relative;
    top: -5px;
}
.form-group.error .form-control{
    border-color:#f00 !important;;
}
.form-group.error .errors-messages{
    color:#f00;
}
.registration-avatar-uploader-block > div {
    margin: 0 auto;
    margin-bottom: 10px;
}
.my-profile .sidebar-menu{

}
.my-profile .sidebar-menu li{

}
.my-profile .sidebar-menu li.active a{
    color:#fff;
    background-color: #2c78eb;
}
.my-profile .sidebar-menu li.active.purple a{
    background: #C400AB;
}

.my-profile .sidebar-menu li a{
    display: block;
    font-size: 19px;
    padding-left: 48px;
    color:#000;
    line-height: 45px;
}
.my-profile .sidebar-menu h2{
    font-size: 28px;

    padding-left: 48px;
    line-height: 60px;
    font-family: 'FuturaDisD';
}
.img-full{
    width: 100%;
}
.container-fluid{
    max-width: 1400px;
}
.btn-bordered-dark{
    border:2px solid #000;
    font-weight: 600;
}
.post-list{}
.post-list .post-item-list{}
.post-list .post-item-list .post-header{padding-left: 20px !important}
.post-list .post-item-list .post-header .author-badge{
    position:absolute;
    top: -6px;
    right: -17px;
}
.post-list .post-item-list .post-statuses{}
.post-list .post-item-list .post-statuses a{ color:#000;font-size: 17px;display: inline-block}
.post-list .post-item-list .post-statuses a.active{
    color:#2c78eb;
}
.post-list .post-item-list .post-title{font-size: 19px}
.post-list .post-item-list .post-button-block{}
.post-list .post-item-list .post-button-block button{
    font-size: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
}


.karma-tabs {

}
.karma-tabs h2{
    font-size: 28px;

    padding-left: 48px;
    line-height: 60px;
    font-family: 'FuturaDisD';
}
.karma-tabs p{
    color:#000;
}
.karma-tabs .tabs-nav{
    text-align: center;
    margin-bottom: 20px;
}

.karma-tabs .tabs-nav li{display: inline-block;padding: 10px}
.karma-tabs .tabs-nav li.active a{
    color:#2c78eb;
}
.karma-tabs .tabs-nav li a{
    font-size: 16px;
    color:#000;
}
.karma-tabs .user-list{}
.karma-tabs .user-list .user-item{
    justify-content: center;
    margin-bottom: 15px;
}
.karma-tabs .user-list .user-item .author-badge{
position: absolute;
    top: 0px;
    right: -20px;

}
.karma-tabs .user-list .user-item .name{
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
}
h2.boldy{
    font-size: 28px;



    font-family: 'FuturaDisD';
}
.pagination{justify-content: left !important;}
.pagination .pagination-item{}
.pagination .pagination-item{color:#000;padding: .5rem .3rem;font-size: 15px;border:0 ;border-left:0 !important;display: inline-flex;align-items: center}

.pagination .pagination-item{background: transparent;color: #000;box-shadow: none}
.pagination .page-item.active{}
.pagination .pagination-item.active {background-color: transparent;color: #2c78eb}


.communities-box{}
.communities-box h2{
    font-size: 20px;
    padding: 0 5px;
}
.communities-box select.sort-by{
    border: 0;
}
.communities-users{
    font-size: 16px;
}
.communities-users .communities-user{
    border:0;
    margin-bottom: 15px;
}
.communities-users .communities-user img{
    width: 100%;
}
.communities-users .communities-user .post-author{
    color:#000;
}


.tab-navs-communities-table {

}
.tab-navs-communities-table li{
    margin-left: 20px;
}
.tab-navs-communities-table li a{
    color:#000;
}
.tab-navs-communities-table li.active a{
    color: #2c78eb;
}


.bm-burger-button {

    width: 36px;
    height: 30px;

}
.bm-burger-button button{
    position: static !important;


}

.bm-burger-button button::before{
    content: "\f0c9";
    font: normal normal normal 24px/1 FontAwesome;
}
.bm-burger-button span{
    display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}
.bm-cross-button span{
    color: #fff;
}
/* Color/shape of close button cross */
.bm-cross {
    background:  #fff;;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    left: 0px;
    top: 0px;
    width: 100%;
    max-width: 360px;
}

/* General sidebar styles */
.bm-menu {
    background: #2c78eb;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
    color:#fff !important;
    text-align: left;
}
.bm-item:hover{
    color:#fff;
    opacity: 0.9;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    left: 0px;
    top: 0px;
}

.tabbar-list{
    background: #fff;
    width: 100%;
    bottom: 0;
    left: 8px;
    position: fixed;
}
.tabbar-list .tabbar-item{

}
.tabbar-list .tabbar-item a{
    color:#000;
    line-height: 60px;
    font-size: 24px;
}
.tabbar-list .tabbar-item a:hover,
.tabbar-list .tabbar-item a.active,
.tabbar-list .tabbar-item a:focus{
    color: #2c78eb;;
}
.mobile-navbar{

}
.mobile-navbar .nav-item{
    font-size: 24px;
    color:#000;
    line-height: 60px;
    text-decoration: none;
    display: inline-block;
    padding: 0 15px;
}
.mobile-navbar .nav-item:hover,
.mobile-navbar .nav-item:focus{
    color: #2c78eb;;

}

.create-post{

}
.create-post .profile-account-holder{
    padding: 30px;
}
.create-post h2{
    font-size: 22px;
    font-family: 'FuturaDisD';
}
.create-post .image-video-radio{
    margin-bottom: 0;
}
.create-post .image-video-radio input[type="radio"]{
    display: none;
}
.create-post .image-video-radio span{
    opacity: 0.5;
}
.create-post .image-video-radio input[type="radio"]:checked + span{
    opacity: 1;
}

.create-post .btn-checkbox-bordered{
    margin-bottom: 0;
}
.create-post .btn-checkbox-bordered input[type="checkbox"]{
    display: none;
}
.create-post .btn-checkbox-bordered span{
    border: 2px solid #000;
    font-weight: 600;
    color: #000;
    opacity: 0.5;
    padding: 6px 18px;

}
.create-post .btn-checkbox-bordered input[type="checkbox"]:checked + span{
    opacity: 1;
}
.create-post .avatar-uploader-block > div{
    border-radius: 0;
    margin: 0 auto;
    border: 0;
}
.create-post .avatar-uploader-block > div img{
    border-radius: 0;
}
.create-post .avatar-uploader-block.photo-block > div{
    margin-bottom: 10px;
}

.settings-main{
    padding: 30px;
}




.room {
    position:fixed;

    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 700;
}
.room .room-overlay{
    position:fixed;
    background-color: rgba(0,0,0,0.8);
    top:0;
    left:0;
    width: 100%;
    height: 100%;
}
.room button {
    transform: rotate(136deg);
   border-radius: 50%;
    padding: 0;
    width: 50px;
    height: 50px;
    font-size: 22px;
}
.room  h3 {
    text-align: center;
    font-weight: 300;

    margin: 0;
}
.room  h5{
    color:#fff
}
.room .local-participant{
    border:1px #f00 solid;
    display: inline-block;
    width:150px;
    position: absolute;
    top: 30px;
    right: 30px;
}
.room .local-participant video{
    width:100%
}

    /* .room-overlay{*/
/*    position: fixed;*/
/*    background-color: #000;*/
/*    opacity: 0.7;*/
/*    top:0;*/
/*    left:0;*/
/*z-index: 1000;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

.local-participant {
    text-align: center;
    margin-bottom: 2em;
}
.remote-participants {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;


}
.participant {
    background:#000;
    padding: 10px;
    border-radius: 6px;
    display: inline-block;
    margin-right: 10px;
}
.participant .title{
    position: absolute;
    left: 30px;
    top: 30px;
}
.participant:last-child {
    margin-right: 0;
}
.participant h3 {
    text-align: center;
    padding-bottom: 0.5em;
    color: #fff;
}
.messenger .call{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}
.messenger .call .dialog-call{
    background-color: #000;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 5px;
    box-shadow: 0px 0px 40px 1px #fff;
}
.messenger .call .dialog-call h3{
    color:#fff;
    margin-bottom: 0;
}
.messenger .call .dialog-call .name{
    color:#fff;
    margin-bottom: 15px;
}

.messenger .call .dialog-call button{
    border-radius: 50%;
    margin: 0 10px;
    width: 50px;
    height: 50px;
    font-size: 22px;
}
.messenger .call .dialog-call button.down{
    transform: rotate(135deg);
}
.btn-warning2{
    color: #fff;
    background-color: #fd5402;
    border-color: #fd5402;
}
.btn-warning2:hover{
    color: #fff;
}
.btn-warning2:focus{
    box-shadow: 0 0 0 0.2rem rgba(	253, 84, 2,.5);
}

.comment-container{}

.comment-container .numbers-comments{
    font-size: 19px ;
    font-weight: 700;
    margin-bottom: 10px;
}
.comment-container .comments-form{
    position: relative;
    margin-bottom: 15px;

}
.comment-container .comments-form input{
    width: 100%;
    font-size: 19px;
    font-weight: 700;
    border:0;
    outline: 0;
    padding-left: 40px;
    padding-right: 40px;
}
.comment-container .comments-form .author-img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top:0;
    left:0
}
.comment-container .comments-form .toolbar-button{
    color: #bbb;
    position: absolute;
    right: 10px;
    top:0;
}
.comment-container .comments-form .toolbar-button:hover{
    color:#999;
}
.comment-container .see-more{
    font-size: 19px;
    color:#bbb;
    font-weight: 700;
}

.comment-container .comments-list {}
.comment-container .comments-list .comment-item{
    margin-bottom: 15px;
}
.comment-container .comments-list .comment-item .comment-img{
    width:30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}
.comment-container .comments-list .comment-item .comment-author{
    font-size: 19px;
    font-weight: 700;
    color:#000;
    margin-right: 20px;
}
.comment-container .comments-list .comment-item .text-comment{
    font-size: 19px;
}

.service-simple .service-data{

}
.service-simple .service-data h1{
    font-size: 21px;
    font-weight: 600;
    padding: 0px 40px;
    padding-top: 15px;
    margin-bottom: 0;
}
.service-simple .service-data .service-params{
    padding: 0px 40px;
}
.service-simple .service-data .service-params .param{
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
    color:#333;
}
.service-simple .service-data .service-params .param:last-child{
    margin-bottom: 0;
}
.service-simple .service-data .button-block{
    padding: 5px 10px;
}
.service-simple .service-data  button{
    font-size: 21px;
}
.service-simple .service-data .footer-service{
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
}
.service-order-result{
    font-size: 24px;
    font-weight: 700;
    height: 150px;
}

.service-place-order{
    padding: 20px 30px;
    font-size: 19px;
    font-weight: 700;
}

.service-place-order .service-place-order-title{
    text-align: center;
    font-size: 22px;
}
.service-place-order span{
    margin-bottom: 10px;
}

.service-place-order .upload-block button{
    margin-bottom: 10px;
}
.service-place-order  button{
    font-size: 19px;
}
.service-place-order textarea{
    margin-bottom: 10px;
    font-size: 19px;
    font-weight: 700;
;
}

.notification-right-sidebar{
    padding: 20px 40px;
}

.notification-right-sidebar .sidebar-position{
    margin-bottom: 30px;
}

.notification-right-sidebar .sidebar-position .numbers{
    font-size: 30px;
    font-weight: 900;
    font-family: 'FuturaDisD';
 }
.notification-right-sidebar .sidebar-position .title{
    font-size: 16px;
    color: #777;
}

.notification  .notification-nav{

}
.notification  .notification-nav ul{
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}
.notification  .notification-nav ul li{
    display: inline-block;
}
.notification  .notification-nav ul li a{
    color:#000;
    font-size: 18px;
    font-weight: 600;
}
.notification  .notification-nav ul li.active a{
    color: #2b78ea;
}
.notification .notification-item{
    display: flex;
    padding: 20px 30px;
    border-bottom: 1px solid #d6d6d6;
}
.notification .notification-item .notification-img{
    width: 50px;
    border-radius: 50%;
    margin-right: 20px;
}
.notification .notification-item .text-block{

}
.notification .notification-item .text-block .name-badge{
    position: relative;
    display: inline-block;
}
.notification .notification-item .text-block .name-badge .name{
    font-size: 17px;
    font-weight: 700;
}
.notification .notification-item .text-block .name-badge .badge{
    position: absolute;
    right: -22px;
    top: -2px;
}
.notification .notification-item .text-block .status{
    font-size: 16px;
    color:#333;
}


.notification .order-list{}
.notification .order-list .order-item{
    padding: 20px 30px;
    border-bottom: 1px solid #d6d6d6;
}
.notification .order-list .order-item .order-user{
    display: flex;
}
.notification .order-list .order-item .order-user .notification-img{
    width: 50px;
    border-radius: 50%;
    margin-right: 20px;
}
.notification .order-list .order-item .order-user .text-block .name-badge{
    position: relative;
    display: inline-block;
}
.notification .order-list .order-item .order-user  .text-block .name-badge .name{
    font-size: 17px;
    font-weight: 700;
}
.notification .order-list .order-item .order-user  .text-block .name-badge .badge{
    position: absolute;
    right: -22px;
    top: -2px;
}
.notification .order-list .order-item .order-user  .text-block .status{
    font-size: 16px;
    color:#333;
}
.notification .order-list .order-item .order-img{
    max-width: 100%;
    width: 120px;
}
.notification .order-list .order-item .order-row{
    margin-bottom: 10px;

}
.notification  .order-list .order-item .row-content{
    margin-bottom: 20px;
    padding-left: 70px;
}
.notification  .order-list .order-item .row-content .row-title{
    font-weight: 600;
    font-size: 16px;
}
.notification  .order-list .order-item .row-content.description-block .description{
    font-size: 16px;
    color: #333;
    font-weight: 500;
}
.notification  .order-list .order-item .row-content .file-list a{
    font-size: 17px;
}
.notification  .order-list.call .order-item .row-content.last{
    margin-bottom: 40px ;
}
.notification   .order-item [class^="react-stars-wrapper"]{
    display: inline-block !important;
}


.notification .earning-header{
    padding: 10px 30px;
}
.notification .earning-header h2{
    font-size: 28px;


    font-family: 'FuturaDisD';
}
.notification .earning-header h3{
    font-weight: 600;
    font-size: 20px;
    color:#777;
}

 .list-edit-images .image-block{
     position: relative;
 }
.list-edit-images .image-block img{
    width: 100%;
}
.list-edit-images .image-block .delete-img{
    display: inline-flex;
    color:#fff;
    background-color: #f00;
    width: 20px;
    height: 20px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    top:-10px;
}
._loading_overlay_wrapper{
    height: 100%;
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 100;
}
.col-lg-20{
    flex: 0 0 20%;
    padding: 0 8px;
}

.request-call-container{}
.request-call-container .form-container{
    padding: 0 50px;
}
.request-call-container .form-container .form-row{}
.request-call-container .form-container .form-row h2{

    font-size: 21px;
    font-weight: 600;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-bottom: 0;

}
.request-call-container .form-container .form-row .radio-button-custom{

}
.request-call-container .form-container .form-row .radio-button-custom span{
    border: 2px solid #000;
    font-weight: 600;
    color: #000;
    opacity: 0.5;
    padding: 6px 18px;
    display: inline-block;
    border-radius:4px;
    width: 100%;
    text-align: center;
}
.request-call-container .form-container .form-row .radio-button-custom input{
    display: none;
}
.request-call-container .form-container .form-row .radio-button-custom input:checked + span {
    opacity: 1;
}
.request-call-container .button-block{
    padding: 5px 10px;

}
.request-call-container .button-block button{
    font-size: 21px;
}
.request-call-container .footer-call-request{

    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
}

.service-post-nav{

}
.service-post-nav .btn-light{
    border:0;
    color:#000;
    background: #fff;
}

.not-found-block{
    color:#000;
}
#react-select-2-input{
    font-size: 16px;
    font-weight: 600;
}
.not-found-block .main-content{
    font-size: 19px;
    text-align: center;
    padding:20px;
}
.not-found-block .main-content img{
    max-width: 450px;
    width:100%;
}
.not-found-block .main-content p{
    color:#000;
    font-weight: 600;
}
.not-found-block .alternative-links{}
.not-found-block .alternative-links .alternative-item{
    border: 1px solid #d6d6d6;
    border-radius: 6px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background: #fff;
    padding: 8px;
    font-size: 19px;
    padding-top: 20px;

}
.not-found-block .alternative-links .alternative-item p{
    font-weight: 600;
    color:#000;
    text-align: center;
}

.not-found-block .alternative-links .alternative-item button{
    padding: 12px 12px;
    font-size: 14px;
}

.not-found-block .alternative-links .alternative-item .fa{
    font-size: 72px;
}

.settings-container{
    padding: 15px 40px;
}
.settings-container h1{
    font-size: 24px;

}
.settings-container h2{
    font-size: 19px;
}

.settings-container form.change-password{

}
.settings-container form.change-password .form-control{
    border: 2px solid #d6d6d6;
    font-size: 19px;
}

.settings-container form.change-password .error .form-control{
    border-color:#f00;

}
.settings-container form.change-password .error .errors_list{
    color:#f00;
}
.settings-container form.change-password button{
    font-size: 19px;
    margin-bottom: 30px;
}
.settings-container form.change-password a{
    font-size: 16px;
    font-weight: 600;

}
.settings-container .billing-information{
    font-size: 19px;
}
.settings-container .billing-information .value{
    color:#777;
}
.settings-container .help-form{

}
.settings-container .help-form textarea{
    border: 2px solid #d6d6d6;
}
.settings-container .help-form button{
    font-size: 19px;
}
.settings-container .help-form select {
    border: 2px solid #d6d6d6;
}
.settings-container .about-settings{}
.settings-container .about-settings .h2{
    font-size: 19px;
}
.settings-container .about-settings p{
    color:#000;
    font-size: 17px;

}
.settings-container .earning-item{
    color:#777;
    font-size: 16px;
    margin-bottom: 10px;
}
.text-page-content{
    padding: 15px 40px;
}
.text-page-content p{
    font-size: 16px;
}
.text-page-content h1{
    font-size: 24px;
}



.search-page{}

.search-filter{
    padding: 16px 24px;
}
.search-filter .params-list{

}
.search-filter .params-list .param-item{

}
.search-filter .params-list .param-item .param-title{
    font-size: 16px;
    font-weight: 600;
}
.search-filter .params-list .param-item .param-input-location{
    border:0;
    font-size: 16px;
}
.search-filter .params-list .param-item select{
    width: 100%;
    font-size: 16px;
    border: 2px solid #000;
    border-radius: 4px;
    padding: 5px;
}
.search-filter .params-list .param-item .radio-filter{
    font-size: 16px;
    display: inline-block;
    margin-right: 20px;
}
.search-filter .params-list .param-item .radio-filter input{
   display: none;
}
.search-filter .params-list .param-item .radio-filter span{
    opacity: 0.7;
}
.search-filter .params-list .param-item .radio-filter input:checked + span{
    opacity: 1;
}
.search-filter .params-list .param-item .param-input-range{
    font-size: 16px;
    display: inline-block;
    width: 50px;
    padding: 5px;
    border:2px solid #000;
    border-radius: 4px;
}
.search-filter .params-list .param-item .range-connector{
    font-size: 16px ;
    display: inline-block;
    margin: 0 10px;
}
.search-filter  button{
    font-size: 19px;
}
.search-content-page{
    width: 100%;
    padding: 15px 24px;
}
.search-content-page .search-field-block{
    position:relative;
}
.search-content-page .search-field-block input{
    width: 100%;
    border: 2px solid #d6d6d6;
    font-size: 16px;
    padding: 4px 40px;
    border-radius: 15px;
    outline: 0;
}
.search-content-page .search-field-block span{
    color:#d6d6d6;
    position: absolute;
    top: 4px;
    left: 8px;
    font-size: 20px;
}
.search-content-page .search-field-block button{
    border-radius: 17px;
    position:absolute;

    top: 4px;
    right: 8px;
    padding: 4px 8px;
}

.search-content-page  .search-description{
    font-size: 19px;
}
.search-content-page .search-sort{
    font-size: 16px;
}
.search-content-page .search-sort .search-sort-radio-block{

}
.search-content-page .search-sort .search-sort-radio-block label{
    display: inline-block;
    margin-left: 10px;
}
.search-content-page .search-sort .search-sort-radio-block input{
    display: none;
}
.search-content-page .search-sort .search-sort-radio-block span{
    opacity:0.7;
}
.search-content-page .search-sort .search-sort-radio-block input:checked + span{
    opacity: 1;
}
.search-content-page .search-results{}
.search-content-page .search-results .search-result-item{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.search-content-page .search-results .search-result-item img{
    width:50px;
    border-radius: 50%;
    margin-right: 20px;
}
.search-content-page .search-results .search-result-item .title{}
.search-content-page .search-results .search-result-item .title .name{
    font-size: 19px;
    line-height: 1;
}
.search-content-page .search-results .search-result-item .title .profession{
    font-size: 14px;
}
@media (max-width: 1200px) {
    .search-page aside.left-sidebar{
        order: 0;
    }
    .search-page main{
        order:2;
    }
    .search-page aside.right-sidebar{
        order:1;
    }
    .search-filter h2{display: none;}
}
.search-field-mobile{
    position:relative;
}
.search-field-mobile input{
    width:100%;
    border: 2px #d6d6d6 solid;
    font-size: 16px;
    padding: 3px 30px;
}
.search-field-mobile a{
    position: absolute;
    top:0;
    left:0;
    color:#000;
    padding: 7px;
}