.message-list-container {
  padding: 10px;
  padding-bottom: 70px;

  bottom: 0;
  width: 100%;
  top:62px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

}

.message-list-container .confirm-buttons{
  background-color:#007aff;
  padding: 15px;
}
.message-list-container .confirm-buttons span{
  color:#fff;
  margin-bottom: 10px;
  font-size: 14px;
}
.message-list-container .confirm-buttons button{
  background-color: #fff;
  color:#007aff;
  padding: 5px 30px;
}
.message-list{
  position: relative;
}