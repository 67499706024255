.feed-context-menu{
    position: fixed;
    top:0;
    left:0;
    width: 100%;

    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.feed-context-menu ul{
    padding: 0;
    list-style-type: none;
    border-radius: 8px;
    border: 1px solid #d6d6d6;
    background: #fff;
    width: 165px;
}
.feed-context-menu ul li{

}
.feed-context-menu ul li a{
    color:#000;
    display: block;
    padding: 10px;
    font-size: 19px;
    border-bottom: 1px solid #d6d6d6;
    font-weight: 600;
    width: 100%;
}
.feed-context-menu ul li a.danger{
    color:#f00 !important;
}
.report-modal{
    font-size: 19px ;
    font-weight: 600;
}
.report-modal label{
    font-weight: 600;
}
.report-modal textarea{
    font-size: 19px;
}
.report-modal textarea::placeholder{
    color:#aaa
}