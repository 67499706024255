.messenger {
  display: grid;
  width: 100%;
  height: calc(100vh - 100px);
  background: #eeeef1;

  grid-template-columns: 320px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}

  .messenger.hide-sidebar {
    grid-template-columns: 0px auto;
  }
  .messenger.hide-sidebar .conversation-list .toolbar-title{
    display: none;
  }
  .messenger.hide-sidebar .conversation-list .left-items{
    display: none;
  }
  .messenger.hide-sidebar .conversation-list .right-items{
    justify-content: center;
  }
  .messenger.hide-sidebar .conversation-list .conversation-search{
    display: none;

  }
  .messenger.hide-sidebar .conversation-list .conversation-list-item{
    display: none;
  }

@media (max-width:767px){
  .messenger{
    grid-template-columns: 100% auto;
  }
}